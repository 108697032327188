<template>
  <div class="body_bgi full border_box">
    <big-screen-header :headerName="'巴林右旗' + siteName + '动防站'" @selectJob="selectJob" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_p h_full flex_column_between">
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
          <div class="text_1 fw_bold jian_bian_text_1">动防站列表</div>
      
          <div class="w_full h_90_p scroll_box overflow_y_auto ml_20">
            <div 
              class="w_full pt_5 cursor text_1 flex_start color_fff" 
              v-for="(site, site_index) in siteList" :key="site_index"
              @click="handleClickSiteItem(site.id, site.name, site.headId)"
            >
              <div 
                class="text_1 hover_main_color"
                :class="{ 'jian_bian_text_1 fw_bold': siteName == site.name }"
              >
                <span class="w_40 font_number" v-if="site_index < 9">0{{ site_index + 1 }}.</span> 
                <span class="w_40 font_number" v-else>{{ site_index + 1 }}.</span> 
                {{ site.name + '动防站' }}
              </div>
            </div>
          </div>
        </div>

        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
          <yi-miao-chart
            title="疫苗库存统计"
            path="/storePage" 
            :chartData="chartData"
            @clickYiMiao="handleClickVaccinum"
            @selectJob="selectJob"
          />
        </div>
      </div> 

      <!-- 2 -->
      <div class="w_32_p h_full flex_column_between">
        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1 scroll_box">
          <div class="text_1 jian_bian_text_1 fw_bold pb_10">站点负责人</div>

          <div class="w_full h_50_p">
            <div class="w_30_p h_full m_0_auto">
              <img 
                v-if="personData.avatar"
                class="border_radius_10 cursor response_img" 
                :src='filePath + `${personData.avatar}`' alt=""
              >

              <img 
                class="border_radius_10 cursor response_img" 
                v-else
                :src='def_user_img' alt=""
              >
            </div>
          </div>

          <div class="w_full h_40_p color_fff pv_20 border_box">
            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">姓名： <span class="main_color">{{ personData.name }}</span> </div>
              <div class="h_24 lh_24 mb_3">民族： <span class="main_color">{{ personData.nationName }}</span></div>
            </div>

            <div class="w_full pr_20">
              <div class="h_24 lh_24 mb_3">
                电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(personData.phone) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
          <div class="text_1 fw_bold jian_bian_text_1">疫苗信息</div>

          <div class="w_full h_full-40 flex_column_between mt_10">
            <!-- 疫苗图片 疫苗信息 -->
            <div class="w_full h_full flex_column_between">
              <div class="w_full h_60_p m_0_auto">
                <img 
                  v-if="currentVaccinumInfo.picture"
                  class="border_radius_5 cursor response_img" 
                  :src='filePath + `${currentVaccinumInfo.picture}`' alt=""
                >

                <img 
                  class="border_radius_5 cursor response_img" 
                  v-else
                  :src='def_yi_miao_img' alt=""
                >
              </div>

              <div class="w_full h_30_p ph_10 main_color">
                <div class="w_full p_5">
                  疫苗名称: 
                  <!-- <span 
                    class="theme_color_green cursor" 
                    @click="goToVacciumnDetail(currentVaccinumInfo.id, currentVaccinumInfo.name)"
                  > -->
                  <span class="theme_color_green cursor">
                    {{ currentVaccinumInfo.name }}
                  </span>
                </div> 

                <div class="w_full h_40 scroll_box overflow_scroll p_5">
                  说明:
                  <span class="color_fff"> {{ currentVaccinumInfo.description }}</span>
                </div>

                <div class="w_full h_40 jian_bian_text_1 fw_bold cursor p_5 ls_2" @click="isShowChageLogDialog = true">
                  查看明细
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <!-- 3 -->
      <div class="w_32_p h_full p_10 border_box bgs_full chart_box_bgi_2">
        <div class="text_1 jian_bian_text_1 fw_bold flex_row_between">
          站点防疫员

          <el-select v-model="fangYiYuanName" size="mini" filterable clearable placeholder="请选择防疫员" @change="filterFangYiYuan">
            <el-option
              v-for="(item, index) in fangYiYuanList" :key="index"
              :label="item.name"
              :value="item.name"
            >    
            </el-option>
          </el-select>
        </div>

        <!-- 防疫员 列表 -->
        <div class="scroll_box w_full h_95_p overflow_auto mt_10">
          <dv-loading v-if="!fangYiYuanList.length" class="color_fff">数据加载中...</dv-loading>
          <div
            class="w_full p_10 border_box flex_row_between" 
            v-for="(item, index) in fangYiYuanList" :key="index"
            @click="goToFangYiYuanPage(item.id)"
          >
            <!-- 头像 -->
            <div class="w_20_p border_radius_5 overflow_hidden">
              <img 
                class="w_full cursor" 
                :src="filePath + item.avatar || def_user_avatar_img" alt=""
              >
            </div>

            <!-- 文字信息 -->
            <div class="info_box w_80_p flex_column_start pl_20 cursor">
              <div class="text_1 color_green">防疫员: {{ item.name || ''}}</div>
              <div class="text_1 color_fff">电话: <span class="font_number ls_2">{{ formatPhone(item.phone) || ''}}</span></div>
              <div class="text_1 color_fff">负责嘎查: <span class="">{{ item.remark || ''}}</span></div>
            </div>
          </div>
        </div> 
      </div> 
    </div>

    <!-- 疫苗进出明细 -->
    <el-dialog
      :title="currentVaccinumInfo.name + '疫苗进出明细'"
      :visible.sync="isShowChageLogDialog"
      width="80%"
    >
      <!-- <el-button type="success" size="mini" @click="exportMingXiData">导出明细</el-button>  -->
      <el-table
        v-if="isShowChageLogDialog"
        :data="vaccinumChangeLogList"
        style="width: 100%"
        size="small"
        max-height="400"
      >
        <el-table-column prop="createTime"  label="时间" width="180" />
        <el-table-column 
          label="类型" width="120" 
          :filters="filterTagList"
          :filter-method="filterChangeType"
          filter-placement="bottom-end"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.changeType == 0" type="primary">入库</el-tag>
            <el-tag v-else-if="scope.row.changeType == 1" type="success">领取</el-tag>
            <el-tag v-else-if="scope.row.changeType == 2" type="info">接收</el-tag>
            <el-tag v-else-if="scope.row.changeType == 3" type="danger">防疫记录作废</el-tag>
            <el-tag v-else-if="scope.row.changeType == 4" type="danger">用量修正+</el-tag>
            <el-tag v-else-if="scope.row.changeType == -1" type="warning">发放</el-tag>
            <el-tag v-else-if="scope.row.changeType == -2" type="danger">退还</el-tag>
            <el-tag v-else-if="scope.row.changeType == -3" type="primary">防疫</el-tag>
            <el-tag v-else-if="scope.row.changeType == -4" type="danger">用量修正-</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="changeNum" label="数量" width="150" align="right">
          <template slot-scope="scope">
            <div class="w_full">
              <span class="color_ff2c64" v-if="scope.row.changeType < 0">- {{ scope.row.changeNum }}</span>
              <span class="color_000" v-else>+ {{ scope.row.changeNum }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="最终库存" width="150" align="right" />
        <el-table-column width="20" />
        <el-table-column prop="remark" label="备注" align="left" />
      </el-table>

      <!-- 分页 -->
      <!-- <div class="w_full h_35 text_right p_10">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next"
          :total="changeLogPaginationParams.total">
        </el-pagination>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isShowChageLogDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import imgMixin from '@/mixins/def-img.js'
  import dataChartMixin from '@/mixins/data-charts.js'

  import { getAntiepidemicPersonList } from '@/api/fang-yi/fang-yi-yuan.js'
  import { getSiteList, getHeadInfoBySiteId } from '@/api/base/base.js'
  import { getInventory, getInventoryChangeLog } from '@/api/fang-yi/yi-miao.js'
  import { getVaccinumTree } from '@/api/fang-yi/fang-yi.js'

  import { filePath } from "@/const"

  export default {
    name: 'ZhanDianPage',
    mixins: [defMixin, componentsMixin, imgMixin, dataChartMixin],
    data() {
      return {
        jobId: null,

        filePath: filePath,

        fangYiYuanName: '',
        fangYiYuanList: [],

        siteList: [],                   // 巴林右旗 所有动防站点

        chartData: {
          unit: '毫升、头份',
          isShowUnit: true,
          data: []
        },

        siteId: '',
        siteName: '',
        siteHeadId: '',

        storeId: null,

        vaccinumList: [],

        // 动防站点 【负责人】 信息
        personData: {
          name: '',
          nationName: '',
          gender: '',
          address: '',
          townName: '',
          phone: ''
        },

        currentVaccinumInfo: {},
        vaccinumChangeLogList: [],

        isShowChageLogDialog: false,
        filterTagList: [
          { text: '领取', value: '1' },
          { text: '发放', value: '-1' },
          { text: '接收', value: '2' },
          { text: '退还', value: '-2' },
        ],
      }
    },

    async mounted() {
      let siteId = this.$route.params.id
      let siteName = this.$route.params.name
      let siteHeadId = this.$route.params.headId

      this.siteId = siteId
      this.siteName = siteName
      this.siteHeadId = siteHeadId
      
      // 获取 全旗 动防站点
      await this.getSiteList()

      // 获取 动防站点 - 负责人信息
      await this.getHeadInfoBySiteId(this.siteHeadId)

      // 获取 动防站的 防疫员列表
      await this.getAntiepidemicPersonList(this.siteId, this.jobId, null)
    },

    methods: {
      // 监听 选择 防疫工作
      selectJob(jobId) {
        this.jobId = jobId

        let params = {
          siteId: this.siteId, 
          jobId, 
          isPersonal: 0,
          page: 1,
          size: 1000
        }

        this.getInventory(params)
      },

      // 筛选 明细
      filterChangeType(value, row) {
        return row.changeType == value
      },

      // 获取 站点的 疫苗库存信息
      async getInventory(params) {
        let res = await getInventory(params)
        try {
          if (res.code == 1000) {
            if (res.data.list.length) {
              this.storeId = res.data.list[0].storeId

              this.vaccinumList = res.data.list

              this.chartData = {
                unit: '毫升、头份',
                isShowUnit: true,
                data: this.formatYiMiaoList(res.data.list)
              }

              // 显示 默认 疫苗详情
              let defaultVaccinum = {id: res.data.list[0].goodsId, name: res.data.list[0].goodsName}
              this.handleClickVaccinum(defaultVaccinum)
            } else {
              this.chartData = {
                unit: '毫升、头份',
                isShowUnit: true,
                data: []
              }

              this.currentVaccinumInfo = {}
              this.vaccinumChangeLogList = []
            }
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 获取 全旗 动防站点
      async getSiteList() {
        let res = await getSiteList()
        try {
          if (res.code == 1000) {
            let isShowMoNi = window.sessionStorage.getItem('isShowMoNi')
            if (isShowMoNi) {
              this.siteList = res.data
            } else {
              this.siteList = res.data.filter(item => item.demo != 1)
            }
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 动防站点-负责人信息
      async getHeadInfoBySiteId(headId) {
        let res = await getHeadInfoBySiteId(headId)
        try {
          if (res.code == 1000) {
            this.personData = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 动防站下的 防疫员列表
      async getAntiepidemicPersonList(siteId, jobId, name) {
        let res = await getAntiepidemicPersonList(siteId, jobId, name);
        try {
          if (res.code == 1000) {
            this.fangYiYuanList = res.data.filter(item => item.isType == 0)
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      filterFangYiYuan(val) {
        this.getAntiepidemicPersonList(this.siteId, this.jobId, val)
      },

      // 跳转到防疫员页面
      goToAntiepidemicPersonListPage(id) {
        this.$router.push(`/fangYiYuanPage/${id}`)
      },

      // 点击动防站名称
      handleClickSiteItem(id, name, headId, townId) {
        this.siteId = id
        this.siteName = name
        this.headId = headId

        // 获取 动防站的 防疫员列表
        this.getAntiepidemicPersonList(id)

        // 获取 当前动防站的 负责人信息
        this.getHeadInfoBySiteId(this.headId)

        // 获取 动防站的 疫苗记录
        let params = {
          siteId: id, 
          jobId: this.jobId, 
          isPersonal: 0,
          page: 1,
          size: 1000
        }
        this.getInventory(params)
      },

      // 监听子组件 传递 的信息
      handleClickVaccinum(val) {
        // 获取疫苗详情
        this.getVaccinumDetail(val.name)

        // 获取 疫苗 动防站的入库记录
        this.getInventoryChangeLog(this.storeId, val.id)
      },

      // 获取 疫苗详情
      async getVaccinumDetail(name) {
        let res = await getVaccinumTree(name);
        try {
          if (res.code == 1000 && res.data[0].children.length && res.data[0].children[0].name) {
            this.currentVaccinumInfo = res.data[0].children[0]
          } else {
            this.currentVaccinumInfo = {
              picture: '',
              name: '',
              description: ''
            }
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      // 获取疫苗的 入库信息
      async getInventoryChangeLog(storeId, goodsId) {
        let params = {
          storeId,
          goodsId,
          page: 1,
          size: 1000
        }
        let res = await getInventoryChangeLog(params);
        try {
          if (res.code == 1000) {
            this.vaccinumChangeLogList = res.data.list
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      // 格式化 图表所用的 数据
      formatYiMiaoList(list) {
        let newList = []
        let item = {}

        for (let i in list) {
          item = { 
            id: list[i].goodsId,
            name: list[i].goodsName,
            value: list[i].quantity,
            unit: '毫升、头份',
          }
          newList.push(item)
        }

        return newList
      }
    }
  }
</script>